import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

const IndexPage = () => {
    return (
        <Layout pageTitle="Virtual Awards - Press Releases">
            <Row>
                <Col>
                    <Link to="/awards/small-business-awards">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}></Col>
            </Row>
            <ValueRow
                name="Alternate Quotes from Angela"
                href="https://docs.google.com/document/d/1x0B_wFBVhQvMPkRVtkNJ2DGpzUHO3DRB9JZcp2Gx_bQ/edit?usp=sharing"
            />
            <ValueRow
                name="Sponsor Announcement"
                href="https://docs.google.com/document/d/1NvxppEBM6p-zRvX9KJ5WvEnDZ6P1t_7m5f9WbtrYDwg/edit?usp=sharing"
            />
            <ValueRow
                name="Award Nominee & Finalist Announcement"
                href="https://docs.google.com/document/d/1QuldsN5cbS8Yrkx4R8t-_Ox5KGq63KeEwVGAYvoeVso/edit?usp=sharing"
            />
            <ValueRow
                name="Award Winners"
                href="https://docs.google.com/document/d/1uZfo4vedb9BXfZKvCsPaKaRJCJj6bjkLMb4uVWd6rOA/edit?usp=sharing"
            />
        </Layout>
    )
}

const ValueRow = ({ name, href }) => {
    return (
        <Row className="mt-5">
            <Col>
                <h3>{name}</h3>
                <a
                    href={href}
                    className="btn btn-primary m-3"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Download Document
                </a>
            </Col>
        </Row>
    )
}

export default IndexPage
